<template>
  <div>
    <Header
      :showBackButton="true"
      :showLogo="false"
      :menuColor="'#fff'"
      class="page-anim1"
    />

    <div class="img-holder">
      <div class="shape"></div>
    </div>

    <div class="holder container">
      <transition enter-active-class="animated zoomIn">
        <img
          v-show="product.image"
          :src="product.image"
          alt=""
          class="product"
        />
      </transition>
      <div class="main">
        <div class="container">
          <div
            v-show="loading"
            class="flex-center desktop12 tablet12 mobile12 mt-3"
          >
            <div class="loading2"></div>
          </div>

          <transition enter-active-class="animated fadeInLeft">
            <div v-show="product.name" style="animation-delay:250ms;">
              <h1 class="title">{{ product.name }}</h1>
              <p class="summary mt-1">{{ product.summary }}</p>
              <p class="desc" v-html="product.description"></p>
            </div>
          </transition>

          <transition enter-active-class="animated fadeInLeft">
            <div v-show="ingredients.length > 0" class="title-holder">
              <Book class="icon" />
              <h1>Ingredientes</h1>
            </div>
          </transition>

          <transition enter-active-class="animated zoomIn">
            <table
              v-show="ingredients.length > 0"
              class="ingredients"
              style="animation-delay:500ms;"
            >
              <tbody>
                <tr v-for="(item, index) in ingredients" :key="index">
                  <td
                    @click="navigateToProduct(item)"
                    :class="{ pointer: item.slug }"
                  >
                    <img
                      class="ingredient-image"
                      v-show="item.image"
                      :src="item.image"
                    />
                    {{ item.name }}
                  </td>
                  <td>{{ `${item.quantity} ${item.measurementUnit}` }}</td>
                  <td>{{ item.price | currency }}</td>
                </tr>
              </tbody>
            </table>
          </transition>

          <transition enter-active-class="animated zoomIn">
            <div
              v-show="product.nutritionalTable"
              class="nutritional-table"
              v-html="product.nutritionalTable"
              style="animation-delay:750ms;"
            ></div>
          </transition>

          <div v-show="product.code" class="cod mt-1">
            Código: {{ product.code }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Liked from "vue-material-design-icons/ThumbUp.vue";
import Favorited from "vue-material-design-icons/Heart.vue";
import Book from "vue-material-design-icons/BookOpenVariant.vue";
import Bowl from "vue-material-design-icons/BowlMix.vue";

export default {
  name: "Home",
  components: {
    Header,
    Liked,
    Favorited,
    Book,
    Bowl,
  },
  data() {
    return {
      loading: true,
      product: {},
    };
  },
  created() {
    this.getProduct();
  },
  computed: {
    ingredients() {
      return this.product.ingredients
        ? JSON.parse(this.product.ingredients)
        : [];
    },
  },
  methods: {
    getProduct() {
      this.loading = true;
      http
        .get(`product/details?id=${this.$route.params.id}`)
        .then((response) => {
          this.product = response.data;
          console.log(this.product);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
.img-holder {
  position: relative;
  width: 100%;
  max-width: 1440px;
  height: 200px;
  margin: 0 auto;
  &::before {
    position: absolute;
    content: "";
    width: 240vw;
    height: 240vw;
    background-color: var(--yellow1);
    border-radius: 100%;
    bottom: 0;
    left: 50%;
    right: 50%;
    margin: 0 auto;
    transform: translateX(-50%);
    z-index: 1;
  }
}

img.product {
  position: relative;
  display: block;
  max-width: 65%;
  max-height: 210px;
  margin: -135px auto 0 auto;
  z-index: 2;
}

.title {
  position: relative;
  color: var(--dark4);
  margin: 25px 0 0 0;
}

.desc {
  position: relative;
  font-size: 15px !important;
  margin: 15px 0 0 0;
  * {
    font-size: 15px !important;
  }
}

.title-holder {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  &.prepare {
    h1 {
      margin-top: 7px;
    }
  }
  span {
    position: relative;
    margin-right: 15px;
  }
  svg {
    color: var(--secondary);
  }
  h1 {
    font-family: fontLight;
    font-size: 1.2em;
    color: var(--secondary);
  }
}

.summary {
  font-size: 15px;
}

.material-design-icon.icon {
  width: 2.4em;
  height: 2.4em;
}

.material-design-icon.icon > .material-design-icon__svg {
  width: 2.4em;
  height: 2.4em;
}

.ingredients {
  position: relative;
  width: 100%;
  margin-top: 10px;
  tr {
    position: relative;
    width: 100%;
    &:nth-child(odd) {
      background-color: #f1f1f1;
    }
    td {
      padding: 12px 8px;
      color: var(--dark4);
      &:nth-child(1) {
        width: 45%;
        font-family: fontMedium;
        font-size: 1.1em;
      }
      &:nth-child(2) {
        text-align: right;
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}

.holder {
  position: relative;
  width: 100%;
  display: grid;
  padding-bottom: 35px;
  @media screen and (max-width: 890px) {
    grid-template-columns: 1fr;
  }
}

.ingredients {
  position: relative;
  width: 100%;
  margin-top: 5px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  tr {
    &:first-child {
      border-radius: 12px 12px 0 0;
      td:last-child {
        border-radius: 0 12px 0 0;
      }
    }
    position: relative;
    width: 100%;
    &:nth-child(odd) {
      background-color: #ffebe8;
    }
    td {
      padding: 12px 8px;
      color: var(--dark4);
      &:nth-child(1) {
        width: 100%;
        font-family: fontMedium;
        font-size: 1.1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &:nth-child(2) {
        text-align: right;
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}

.ingredient-image {
  position: relative;
  max-width: 22px;
  max-height: 20px;
  margin-right: 10px;
}

@media screen and (min-width: 1160px) {
}
</style>
